<template>
    <MobileHeader :headerNavItems="headerNavItems" v-if="isMobile" />
    <DesktopHeader :headerNavItems="headerNavItems" v-else />
  </template>
  <script>
  import lang from '@/lang';
  import Navigation from '@/lang/Navigation';
  import MobileHeader from './HeaderMobile.vue';
  import DesktopHeader from './HeaderDesktop.vue';

  export default {
    name: 'Header',
    components: {
      DesktopHeader,
      MobileHeader,
    },
    i18n: {
      messages: Navigation,
      sharedMessages: lang,
    },
    computed: {
      isMobile() {
        return this.$resize && this.$mq.below(1030);
      },
      headerNavItems() {
        const list = [
          {
            name: this.$i18n.t('locateACharger'),
            linkTo: `locate-charger-${this.$i18n.locale}`,
            nestedLinks: [],
          },
          {
            name: this.$i18n.t('forDrivers'),
            linkTo: null,
            nestedLinks: [
              {
                name: this.$i18n.t('gettingStarted.name'),
                description: this.$i18n.t('gettingStarted.description'),
                icon: 'getting-started',
                linkTo: `getting-started-${this.$i18n.locale}`,
                isExternal: false,
              },
              {
                name: this.$i18n.t('chargingEv.name'),
                description: this.$i18n.t('chargingEv.description'),
                icon: 'charging-your-ev',
                linkTo: `charging-your-ev-${this.$i18n.locale}`,
                isExternal: false,
              },
              {
                name: this.$i18n.t('whatToExpectNav.name'),
                description: this.$i18n.t('whatToExpectNav.description'),
                icon: 'what-to-expect',
                linkTo: `what-to-expect-${this.$i18n.locale}`,
                isExternal: false,
              },
              {
                name: this.$i18n.t('pricingPayments.name'),
                description: this.$i18n.t('pricingPayments.description'),
                icon: 'pricing',
                linkTo: `pricing-${this.$i18n.locale}`,
                isExternal: false,
              },
              {
                name: this.$i18n.t('premiumOffers.name'),
                description: this.$i18n.t('premiumOffers.description'),
                icon: 'premium-offers',
                linkTo: `premium-offers-${this.$i18n.locale}`,
                isExternal: false,
              },
              {
                name: this.$i18n.t('signUpForUpdates.name'),
                description: this.$i18n.t('signUpForUpdates.description'),
                icon: 'signup',
                linkTo: `sign-up-${this.$i18n.locale}`,
                isExternal: false,
              },
              {
                name: this.$i18n.t('getTheApp.name'),
                description: this.$i18n.t('getTheApp.description'),
                icon: 'mobile-app',
                linkTo: `mobile-app-${this.$i18n.locale}`,
                isExternal: false,
              },
            ],
          },
          {
            name: this.$i18n.t('forBusiness'),
            linkTo: null,
            nestedLinks: [

              {
                name: this.$i18n.t('becomeSiteHost.name'),
                description: this.$i18n.t('becomeSiteHost.description'),
                icon: 'realestate',
                linkTo: `real-estate-${this.$i18n.locale}`,
                isExternal: false,
              }
            ],
          },
          {
            name: this.$i18n.t('aboutElectrify.title'),
            linkTo: null,
            nestedLinks: [
              {
                name: this.$i18n.t('aboutElectrify.name'),
                description: this.$i18n.t('aboutElectrify.description'),
                icon: 'about-us',
                linkTo: `about-us-${this.$i18n.locale}`,
                isExternal: false,
              },

              {
                name: this.$i18n.t('ourTeamNav.name'),
                description: this.$i18n.t('ourTeamNav.description'),
                icon: 'our-team',
                linkTo: `our-team-${this.$i18n.locale}`,
                isExternal: false,
              },

              {
                name: this.$i18n.t('newsRoom.name'),
                description: this.$i18n.t('newsRoom.description'),
                icon: 'newsroom',
                linkTo: 'https://media.electrify-canada.ca/',
                isExternal: true,
              },
            ],
          },
        ]
        return list;
      }
    },
    watch: {
      $resize() {},
    },
  };
  </script>
